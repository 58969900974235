import { FunctionalComponent, h } from "preact";
import { Link } from "preact-router/match";


const Header: FunctionalComponent = () => {
  return (
    <header class="hero">
      <div class="hero-body">
        <h1 class="title">Screenmeet Cobrowse Connection Health Check</h1>
      </div>
    </header>
  );
};

export default Header;
